import { Injectable } from "@angular/core";
import { MeasurementUnit } from "@data/schemas/measurement-unit/measurement-unit";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class MeasurementUnitService {
	private readonly MEASUREMENT_UNIT_ENDPOINT = "measurement-units";

	constructor(private dataBff: BaseCrudBffService) {}

	getAllMeasurementUnit(): Observable<MeasurementUnit[]> {
		return this.dataBff.getAll<MeasurementUnit[]>({
			path: this.MEASUREMENT_UNIT_ENDPOINT
		});
	}
}
