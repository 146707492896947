import { Component, inject, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Company } from "@data/schemas/company/company";
import { CompanyService } from "@data/services/company.service";
import { CreateSalesOrderService } from "@data/services/create-sales-order.service";
import { SelectPillOptions } from "@shared/components/atlas/select-pill/types";
import { map, Observable, Observer, of, switchMap, takeUntil } from "rxjs";
import { FormStepOneCreateSalesOrder } from "../types/form-sales-order.type";

@Component({
	selector: "app-dados-do-cliente-pedido",
	templateUrl: "./dados-do-cliente-pedido.component.html",
	styleUrls: ["./dados-do-cliente-pedido.component.scss"]
})
export class DadosDoClientePedidoComponent implements OnInit {
	@Input() formStepOne!: FormGroup<FormStepOneCreateSalesOrder>;

	private readonly createSalesOrderService = inject(CreateSalesOrderService);
	private readonly companyService = inject(CompanyService);

	protected selectPillOptions: SelectPillOptions[] = [
		{ label: "Cliente ou fornecedor cadastrado", value: 0 },
		{ label: "Novo cliente ou fornecedor", value: 1 }
	];
	protected suggestions$?: Observable<Company[]>;
	protected showOptions = false;
	protected isCollapsed = true;

	ngOnInit(): void {
		this.searchAutoComplete();
		this.formStepOne.controls.accountType.valueChanges
			.pipe(
				takeUntil(this.createSalesOrderService.destroyCreateSalesOrder$)
			)
			.subscribe(console.log);
	}

	private searchAutoComplete(): void {
		this.suggestions$ = new Observable((observer: Observer<string>) => {
			observer.next(this.formStepOne.getRawValue().razaoSocial);
		}).pipe(
			switchMap((search: string) => {
				return search
					? this.companyService.searchCompanies(search)
					: of([]);
			})
		);
	}

	protected selectedClientVendor(value: unknown): void {
		if (this.isCompany(value)) {
			this.formStepOne.patchValue({
				id: value.id,
				email: value.email,
				cnpj: value.cnpj,
				telefone1: value.telefone1,
				nomeFantasia: value.nomeFantasia
			});
			this.showOptions = true;
		}
	}

	protected isCompany(value: unknown): value is Company {
		return "email" in (value as Company);
	}

	get selectedTypeahead$() {
		return this.suggestions$?.pipe(map((result) => result.length > 0));
	}

	get isNewAccountClientVendor(): boolean {
		return this.formStepOne.getRawValue().accountType === 1;
	}
}
