<div class="d-flex flex-wrap justify-content-between">
	<div class="forms-spacing-content-top">
		<h4
			class="fw-semibold mt-4 display-product-title me-2"
			(click)="toggleProduct()"
			[attr.aria-expanded]="!isExpanded"
			aria-controls="collapseBasic"
		>
			{{ index + 1 }} - {{ title }}
		</h4>
		<i
			(click)="toggleProduct()"
			[ngClass]="{
				'bi-chevron-down': !isExpanded,
				'bi-chevron-up': isExpanded
			}"
			class="bi cursor-pointer"
		></i>
	</div>

	<span style="align-self: center" class="forms-spacing-content-top">
		<span
			*ngIf="itemAlreadySubmitted"
			(click)="editForm()"
			style="align-self: center"
			class="link-primary cursor-pointer small me-2"
		>
			<i class="bi bi-pencil"></i> Editar
		</span>

		<span
			(click)="removeForm()"
			style="align-self: center"
			class="link-danger cursor-pointer small"
		>
			<i class="bi bi-trash"></i> Remover
		</span>
	</span>
</div>

<div class="forms-spacing-content-top">
	<div class="d-flex flex-wrap justify-content-between">
		<div class="d-flex flex-wrap">
			<span class="header-resume-product-label">
				Quantidade: <span class="text-muted">{{ qtde }} |&nbsp;</span>
			</span>

			<span class="header-resume-product-label">
				Preço Unitário:
				<span class="text-muted">
					{{
						precoTotal
							| currency: "BRL" : "symbol" : "1.2-2"
							| placeholder: "não informado"
					}}
					|&nbsp;
				</span>
			</span>

			<span class="header-resume-product-label">
				Preço Total:
				<span class="text-muted">
					{{
						precoTotal
							| currency: "BRL" : "symbol" : "1.2-2"
							| placeholder: "não informado"
					}}
				</span>
			</span>
		</div>

		<div *ngIf="itemAlreadySubmitted" class="forms-spacing-content-top">
			<span class="text-success"
				><i class="bi bi-check2-circle"></i> Item incluído na proposta
				comercial</span
			>
		</div>
	</div>
	<hr />
</div>
