import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { Attachment } from "@data/schemas/attachment";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable } from "rxjs";
import { AuthService } from "@core/services/auth/auth.service";

@Injectable({
	providedIn: "root"
})
export class DocumentsService {
	private readonly API_BFF_URL: string;
	private readonly ATTACHMENT_ENDPOINT = "attachments";

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig,
		private baseCrudBff: BaseCrudBffService
	) {
		this.API_BFF_URL = `${this.config.environment.API_BFF}`;
	}

	uploadMultipleFilesLegacy(formData: FormData): Observable<unknown[]> {
		return this.http.post<unknown[]>(
			`${this.API_BFF_URL}/attachments/upload-legacy`,
			formData,
			{ withCredentials: true }
		);
	}

	uploadMultipleFiles(formData: FormData): Observable<unknown[]> {
		return this.http.post<unknown[]>(
			`${this.API_BFF_URL}/attachments/upload`,
			formData,
			{ withCredentials: true }
		);
	}

	getAttachmentById(id: number): Observable<Attachment[]> {
		return this.baseCrudBff.getById<Attachment[]>(
			this.ATTACHMENT_ENDPOINT,
			id
		);
	}

	downloadAttach({ id }: { id: number }): Observable<Blob> {
		let headers = new HttpHeaders();
		headers = headers.set("Accept", "application/pdf");

		return this.http.get(
			`${this.API_BFF_URL}/attachments/${id}/download?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				headers: headers,
				responseType: "blob",
				withCredentials: true
			}
		);
	}

	deleteAttachment(id: number): Observable<Attachment> {
		return this.baseCrudBff.deleteWithBody<Attachment>(
			this.ATTACHMENT_ENDPOINT,
			id
		);
	}
}
