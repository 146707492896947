import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "app-header-servico",
	templateUrl: "./header-servico.component.html",
	styleUrls: ["./header-servico.component.scss"]
})
export class HeaderServicoComponent {
	@Input() index = 0;
	@Input() isExpanded = false;
	@Input() title = "";
	@Input() qtde = 0;
	@Input() precoUnitario = 0;
	@Input() precoTotal = 0;
	@Input() itemAlreadySubmitted = false;

	@Output() toggleProductForm = new EventEmitter<number>();
	@Output() removeFormFromArray = new EventEmitter<number>();
	@Output() editFormFromArray = new EventEmitter<number>();

	toggleProduct(): void {
		this.toggleProductForm.emit(this.index);
	}

	removeForm(): void {
		this.removeFormFromArray.emit(this.index);
	}

	editForm(): void {
		this.editFormFromArray.emit(this.index);
	}
}
