import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CreateBusinessProposalService {
	private actualStepFromCreateBusinessProposalWizard =
		new BehaviorSubject<number>(0);
	actualStepFromCreateBusinessProposalWizard$ =
		this.actualStepFromCreateBusinessProposalWizard.asObservable();

	setActualStepFromCreateBusinessProposalWizard(step: number): void {
		this.actualStepFromCreateBusinessProposalWizard.next(step);
	}
}
