<h4 class="fw-semibold atlas-spacing-my-4">
	Confira seus dados para finalizar sua Proposta Comercial
</h4>

<div class="row">
	<div class="col-md-6 col-12">
		<app-card>
			<ng-template appAutomataTemplate="cardHeader">
				<h5 class="text-primary">Informações do cliente</h5>
				<span
					(click)="handleGoToStep(0)"
					class="d-flex d-md-inline-flex edit-link cursor-pointer"
					><i class="bi bi-pencil me-2"></i>Editar</span
				>
			</ng-template>
			<ng-template appAutomataTemplate="cardBody">
				<div class="my-2">
					<label class="mb-2 text-muted">Cliente</label>
					<p class="fw-semibold">
						{{ getFormControlValueStepOne("razaoSocial") }}
					</p>
				</div>
				<div class="my-2" *ngIf="!!getFormControlValueStepOne('cnpj')">
					<label class="mb-2 text-muted">CPF/CNPJ</label>
					<p class="fw-semibold">
						{{ getFormControlValueStepOne("cnpj") | cnpj }}
					</p>
				</div>
				<div class="my-2">
					<label class="mb-2 text-muted">Email</label>
					<p class="fw-semibold">
						{{ getFormControlValueStepOne("email") }}
					</p>
				</div>
				<div
					class="my-2"
					*ngIf="!!getFormControlValueStepOne('telefone1')"
				>
					<label class="mb-2 text-muted">Celular</label>
					<p class="fw-semibold">
						{{
							getFormControlValueStepOne("telefone1")
								| cellphoneNumber
						}}
					</p>
				</div>
			</ng-template>
		</app-card>
	</div>
	<div class="col-md-6 col-12 atlas-spacing-mt-4 mt-md-0">
		<app-card>
			<ng-template appAutomataTemplate="cardHeader">
				<h5 class="text-primary">Informações de pagamento</h5>
				<span
					(click)="handleGoToStep(2)"
					class="d-flex d-md-inline-flex edit-link cursor-pointer"
					><i class="bi bi-pencil me-2"></i>Editar</span
				>
			</ng-template>

			<ng-template appAutomataTemplate="cardBody">
				<ng-container
					*ngIf="updatePaymentInfo$ | async as updatePaymentInfo"
				>
					<div class="my-2" *ngIf="!!updatePaymentInfo.valorDesconto">
						<label class="mb-2 text-muted">Desconto</label>
						<p class="fw-semibold">
							{{
								updatePaymentInfo.valorDesconto | currencyLocale
							}}
						</p>
					</div>
					<div class="my-2" *ngIf="!!updatePaymentInfo.valorFrete">
						<label class="mb-2 text-muted">Frete</label>
						<p class="fw-semibold">
							{{ updatePaymentInfo.valorFrete | currencyLocale }}
						</p>
					</div>
					<div class="my-2" *ngIf="!!updatePaymentInfo.totalServico">
						<label class="mb-2 text-muted">Total serviços</label>
						<p class="fw-semibold">
							{{
								updatePaymentInfo.totalServico | currencyLocale
							}}
						</p>
					</div>
					<div class="my-2" *ngIf="!!updatePaymentInfo.totalProduto">
						<label class="mb-2 text-muted">Total produtos</label>
						<p class="fw-semibold">
							{{
								updatePaymentInfo.totalProduto | currencyLocale
							}}
						</p>
					</div>
					<div
						class="my-2"
						*ngIf="
							!!getFormControlValueStepThree('valorTotalFinal')
						"
					>
						<label class="mb-2 text-muted">Valor total final</label>
						<p class="fw-semibold">
							{{
								+getFormControlValueStepThree("valorTotalFinal")
									| currencyLocale
							}}
						</p>
					</div>
					<ng-container
						*ngIf="{
							generateBilling: generateBilling$ | async
						} as context"
					>
						<ng-container *ngIf="!context.generateBilling">
							<div class="my-2">
								<label class="mb-2 text-muted"
									>Como seu cliente irá pagar</label
								>
								<p class="fw-semibold">
									{{
										getActualPaymentMethods(
											getFormControlValueStepThree(
												"formaPagamentoNotAsaas"
											)
										)
									}}
								</p>
							</div>
							<div class="my-2">
								<label class="mb-2 text-muted"
									>Número máximo de parcelas</label
								>
								<p class="fw-semibold">
									{{
										getActualPaymentMethodsInstallments(
											getFormControlValueStepThree(
												"tipoParcelamento"
											)
										)
									}}
								</p>
							</div>
						</ng-container>
						<ng-container *ngIf="context.generateBilling">
							<div class="my-2">
								<label class="mb-2 text-muted"
									>Forma de pagamento</label
								>
								<p class="fw-semibold">
									{{
										updatePaymentInfo.billingTypeAsaasEnum
											?.descricao
									}}
								</p>
							</div>
						</ng-container>
					</ng-container>
					<div *ngIf="!!getSalesProposalValidity()" class="my-2">
						<label class="mb-2 text-muted"
							>Vencimento da proposta</label
						>
						<p class="fw-semibold">
							{{ getSalesProposalValidity() | date }}
						</p>
					</div>
				</ng-container>
			</ng-template>
		</app-card>
	</div>
</div>

<div class="row atlas-spacing-mt-4">
	<div class="col-12">
		<app-card>
			<ng-template appAutomataTemplate="cardHeader">
				<h5 class="text-primary">Produtos/serviços da proposta</h5>
				<span
					(click)="handleGoToStep(1)"
					class="d-flex d-md-inline-flex edit-link cursor-pointer"
					><i class="bi bi-pencil me-2"></i>Editar</span
				>
			</ng-template>
			<ng-template appAutomataTemplate="cardBody">
				<form *ngIf="formStepTwo" [formGroup]="formStepTwo">
					<ng-container formArrayName="selectedProducts">
						<ng-container
							*ngIf="
								selectedProductsFormArray.controls.length > 0
							"
						>
							<div class="table-responsive">
								<table class="table table-borderless">
									<tbody>
										<ng-container
											*ngFor="
												let selectedProduct of selectedProductsFormArray.controls;
												let i = index
											"
										>
											<tr>
												<td>
													<span class="title-row"
														>Nome</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get(
																	"descricao"
																)
																?.getRawValue()
														}}
													</div>
												</td>
												<td>
													<span class="title-row"
														>Tipo</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get(
																	"produtoServico"
																)
																?.getRawValue()
														}}
													</div>
												</td>
												<td>
													<span class="title-row"
														>Código Interno</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get(
																	"codigoInterno"
																)
																?.getRawValue()
														}}
													</div>
												</td>
												<td>
													<span class="title-row"
														>Qtde.</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get("qtde")
																?.getRawValue()
														}}
													</div>
												</td>
												<td>
													<span class="title-row"
														>Preço Unitário</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get(
																	"precoUnitario"
																)
																?.getRawValue()
																| currency
																	: "BRL"
														}}
													</div>
												</td>
												<td>
													<span class="title-row"
														>Preço Total</span
													>
													<div class="fw-semibold">
														{{
															selectedProduct
																.get(
																	"precoTotal"
																)
																?.getRawValue()
																| currency
																	: "BRL"
														}}
													</div>
												</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
								<hr />
								<div class="text-end">
									<span>
										<span class="text-muted"
											>Valor total dos itens</span
										>
										<span class="fs-3 fw-semibold ms-2">{{
											showProductsTotalPrice
												| currency: "BRL"
										}}</span></span
									>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</form>
			</ng-template>
		</app-card>
	</div>
</div>

<div *ngIf="filesToUpload.length > 0" class="row atlas-spacing-mt-4">
	<div class="col-12">
		<app-card>
			<ng-template appAutomataTemplate="cardHeader">
				<h5 class="text-primary">Documentos e arquivos da cobrança</h5>
				<span
					(click)="handleGoToStep(3)"
					class="d-flex d-md-inline-flex edit-link cursor-pointer"
					><i class="bi bi-pencil me-2"></i>Editar</span
				>
			</ng-template>
			<ng-template appAutomataTemplate="cardBody">
				<h6 class="fw-semibold ms-4 mt-2">Nome do arquivo</h6>
				<ng-container *ngFor="let file of filesToUpload; let i = index">
					<div class="body-file my-2">
						<i
							class="bi bi-file-earmark-text icon me-2 align-middle"
						></i>
						<span class="text-muted align-middle">{{
							file.name
						}}</span>
					</div>
				</ng-container>
			</ng-template>
		</app-card>
	</div>
</div>

<div class="atlas-spacing-mt-4">
	<h4 class="fw-semibold atlas-spacing-mb-2">
		Como a proposta comercial será enviada?
	</h4>
	<p class="text-muted atlas-spacing-mb-3">
		Escolha por quais meios de comunicação você deseja enviar a proposta
		para o seu cliente
	</p>
	<app-button
		(clickBtn)="sendEmailSalesProposal()"
		icon="bi bi-envelope"
		[isRounded]="true"
		[isLoading]="isLoadingSendEmailBtn"
	>
		E-mail
	</app-button>
</div>

<div class="atlas-spacing-mt-4">
	<h4 class="fw-semibold atlas-spacing-mb-2">
		Gostaria de baixar a proposta?
	</h4>
	<p class="text-muted atlas-spacing-mb-3">
		Um arquivo PDF será gerado para você salvar em sua máquina
	</p>

	<app-button
		[isLoading]="isLoadingDownloadBtn"
		(clickBtn)="downloadSalesProposalPDF()"
		icon="bi bi-download"
	>
		Baixar proposta
	</app-button>
</div>
