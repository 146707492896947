import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class PaymentService {
	private generateBilling = new BehaviorSubject<boolean>(false);
	generateBilling$ = this.generateBilling.asObservable();

	setGenerateBilling(status: boolean): void {
		this.generateBilling.next(status);
	}

	getLastGenerateBillingValue(): boolean {
		return this.generateBilling.getValue();
	}
}
