<ng-container *ngIf="formStepOne">
	<form [formGroup]="formStepOne">
		<h4 class="fw-bold mt-4">Para quem será enviado?</h4>
		<div class="row">
			<div class="col-12 col-md-6 mt-2">
				<app-select-pill
					[options]="selectPillOptions"
					formControlName="accountType"
				></app-select-pill>
				<div class="mt-3">
					<ng-container *ngIf="isNewAccountClientVendor">
						<app-input
							#input
							id="razaoSocial"
							formControlName="razaoSocial"
							[labelText]="'CLIENT_DATA.LABEL.CLIENT' | translate"
							[isRequired]="true"
							[showErrors]="true"
							[placeholderText]="
								'CLIENT_DATA.PLACEHOLDER.CLIENT' | translate
							"
						></app-input>
					</ng-container>

					<ng-container *ngIf="!isNewAccountClientVendor">
					</ng-container>
				</div>
			</div>
		</div>
		<ng-container *ngIf="showOptions || isNewAccountClientVendor">
			<ng-container
				*ngTemplateOutlet="
					showOptionsTemplate;
					context: { form: formStepOne }
				"
			></ng-container>
			<ng-container
				*ngTemplateOutlet="moreInfo; context: { form: formStepOne }"
			></ng-container>
		</ng-container>
	</form>
</ng-container>

<ng-template #showOptionsTemplate let-form="form">
	<div [formGroup]="form">
		<div class="row mt-4">
			<div class="col-md-4 col-12">
				<app-input
					[labelText]="'CLIENT_DATA.LABEL.CPFCNPJ' | translate"
					[placeholderText]="
						'CLIENT_DATA.PLACEHOLDER.CPFCNPJ' | translate
					"
					[isRequired]="false"
					formControlName="cnpj"
					[showErrors]="true"
					mask="000.000.000-00||00.000.000/0000-00"
				></app-input>
			</div>
			<div class="col-md-4 col-12">
				<app-input
					[labelText]="'CLIENT_DATA.LABEL.EMAIL' | translate"
					[placeholderText]="
						'CLIENT_DATA.PLACEHOLDER.EMAIL' | translate
					"
					[isRequired]="true"
					formControlName="email"
					type="email"
					[showErrors]="true"
				></app-input>
			</div>
			<div class="col-md-4 col-12">
				<app-input
					[labelText]="'CLIENT_DATA.LABEL.CELLPHONE' | translate"
					[placeholderText]="
						'CLIENT_DATA.PLACEHOLDER.CELLPHONE' | translate
					"
					[isRequired]="false"
					formControlName="telefone1"
					[showErrors]="true"
					mask="(00) 00000-0000"
				></app-input>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #moreInfo let-form="form">
	<div [formGroup]="form">
		<h4
			class="fw-bold mt-4"
			(click)="isCollapsed = !isCollapsed"
			[attr.aria-expanded]="!isCollapsed"
		>
			{{ "CLIENT_DATA.TITLE.MORE_OPTIONS" | translate }}
			<i
				[ngClass]="{
					'bi-chevron-down': isCollapsed,
					'bi-chevron-up': !isCollapsed
				}"
				class="bi cursor-pointer"
			></i>
		</h4>
		<hr />
		<div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
			<div class="row">
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.NAME' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.NAME' | translate
						"
						[isRequired]="true"
						formControlName="nomeFantasia"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.PHONE' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.PHONE' | translate
						"
						[isRequired]="true"
						formControlName="phone"
						[showErrors]="true"
						mask="(00) 0000-0000"
					></app-input>
				</div>
				<div class="col-md-4 col-12"></div>
			</div>
			<div class="row mt-3">
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.CEP' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.CEP' | translate
						"
						[isRequired]="true"
						formControlName="cep"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.CITY' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.CITY' | translate
						"
						[isRequired]="true"
						formControlName="cidade"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.STREET' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.STREET' | translate
						"
						[isRequired]="true"
						formControlName="endereco"
						[showErrors]="true"
					></app-input>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="'CLIENT_DATA.LABEL.DISTRICT' | translate"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.DISTRICT' | translate
						"
						[isRequired]="true"
						formControlName="bairro"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="
							'CLIENT_DATA.LABEL.ADDRESSNUMBER' | translate
						"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.ADDRESSNUMBER' | translate
						"
						[isRequired]="true"
						formControlName="numero"
						[showErrors]="true"
					></app-input>
				</div>
				<div class="col-md-4 col-12">
					<app-input
						[labelText]="
							'CLIENT_DATA.LABEL.ADDRESSCOMPLEMENT' | translate
						"
						[placeholderText]="
							'CLIENT_DATA.PLACEHOLDER.ADDRESSCOMPLEMENT'
								| translate
						"
						[isRequired]="true"
						formControlName="complemento"
						[showErrors]="true"
					></app-input>
				</div>
			</div>
		</div>
	</div>
</ng-template>
