<ng-container *ngIf="{ generateBilling: generateBilling$ | async } as context">
	<form [formGroup]="formStepThree">
		<app-header-pagamento sectionTitle="Total dos itens">
			<div class="col-12 col-lg-4">
				<app-input-number
					label="Valor total dos itens"
					placeholder="0,00"
					formControlName="totalItens"
					[updateCurrencyProgrammatically]="true"
					[isRequired]="true"
					inputGroupIconText="R$"
					inputGroupIconPosition="left"
					mode="currency"
					[loading]="loadingTotalValueInput"
				></app-input-number>
			</div>
		</app-header-pagamento>

		<app-header-pagamento sectionTitle="Frete">
			<div class="col-12 col-lg-4">
				<app-input-number
					mode="currency"
					label="Valor do frete"
					placeholder="0,00"
					formControlName="valorFrete"
					[isRequired]="false"
					inputGroupIconText="R$"
					inputGroupIconPosition="left"
				></app-input-number>
			</div>
		</app-header-pagamento>

		<app-header-pagamento sectionTitle="Desconto">
			<div class="col-12 col-lg-6">
				<app-select-pill
					[options]="selectPillOptions"
					formControlName="discountType"
				></app-select-pill>
			</div>
		</app-header-pagamento>

		<div class="row atlas-spacing-mt-3">
			<div class="col-12 col-lg-4">
				<app-input-number
					mode="currency"
					label="Valor do desconto"
					placeholder="0,00"
					formControlName="valorDesconto"
					[isRequired]="false"
					[inputGroupIconText]="getDiscountType() ? '%' : 'R$'"
					inputGroupIconPosition="left"
				></app-input-number>
			</div>
		</div>

		<app-header-pagamento
			sectionTitle="Total da proposta"
			[showPopover]="true"
			popoverTitle="Total da proposta"
			popoverDescription="Para calcular o valor total da proposta, o valor dos itens é somado ao valor do frete e diminuído o valor do desconto, se houver."
			popoverPosition="top"
		>
			<div class="col-12 col-lg-4">
				<app-input-number
					label="Valor total final"
					placeholder="0,00"
					formControlName="valorTotalFinal"
					[updateCurrencyProgrammatically]="true"
					[isRequired]="true"
					inputGroupIconText="R$"
					inputGroupIconPosition="left"
					mode="currency"
					[loading]="loadingTotalValueInput"
				></app-input-number>
			</div>
		</app-header-pagamento>

		<app-header-pagamento sectionTitle="Qual será a forma de pagamento?">
			<ng-container *ngIf="!context.generateBilling">
				<div class="mb-4">
					<div class="col-lg-6 col-12">
						<ng-container
							*ngIf="
								paymentMethodsNotAsaas$
									| async as paymentMethodsNotAsaas
							"
						>
							<label class="mb-2" for="formaPagamentoNotAsaas"
								>Forma de pagamento</label
							>
							<ng-select
								(click)="trackPaymentMethodInput()"
								#selectPaymentMethodsNotAsaas
								[searchable]="false"
								[clearable]="false"
								[items]="paymentMethodsNotAsaas"
								bindLabel="descricao"
								bindValue="id"
								formControlName="formaPagamentoNotAsaas"
							>
								<ng-template ng-header-tmp>
									<input
										class="form-control"
										type="text"
										placeholder="Digite aqui para pesquisar..."
										(input)="
											customNgSelectInputSearch(
												selectPaymentMethodsNotAsaas,
												$event
											)
										"
									/>
								</ng-template>
								<ng-template
									ng-notfound-tmp
									let-searchTerm="searchTerm"
								>
									<div class="ng-option disabled">
										Não foram encontrados resultados para
										"{{ searchTerm }}"
									</div>
								</ng-template>
							</ng-select>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<div class="col-12 col-lg-6" *ngIf="!context.generateBilling">
				<div class="d-flex">
					<label class="mb-2" for="tipoParcelamento"
						>Parcelamento</label
					>
					<span
						tabindex="0"
						class="mb-2 ms-1 align-self-center cursor-pointer text-primary"
						popoverTitle="Parcelamento"
						popover="Valor mínimo para parcelamento: R$ 10,00"
						[adaptivePosition]="true"
						container="body"
						boundariesElement="viewport"
						placement="top"
						containerClass="popover-body"
					>
						<i class="bi bi-info-circle popover-info"></i>
					</span>
				</div>
				<ng-select
					#selectPaymethodMethodsInstallments
					[searchable]="false"
					[clearable]="false"
					[items]="paymethodMethodsInstallments"
					bindValue="idInstallment"
					formControlName="tipoParcelamento"
					[searchFn]="customSearchFn"
					dropdownPosition="top"
				>
					<ng-template ng-header-tmp>
						<input
							class="form-control"
							type="text"
							placeholder="Digite aqui para pesquisar..."
							(input)="
								customNgSelectInputSearch(
									selectPaymethodMethodsInstallments,
									$event
								)
							"
						/>
					</ng-template>

					<ng-template ng-option-tmp let-item="item">
						{{ item.description }} ({{
							item.valueFromAsaas
								| currency: "BRL" : "symbol" : "1.2-2"
						}})
					</ng-template>

					<ng-template ng-label-tmp let-item="item">
						{{ item.description }} ({{
							item.valueFromAsaas
								| currency: "BRL" : "symbol" : "1.2-2"
						}})
					</ng-template>
					<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
						<div class="ng-option disabled">
							Não foram encontrados resultados para "{{
								searchTerm
							}}"
						</div>
					</ng-template>
				</ng-select>
			</div>

			<div class="col-12 col-lg-6" *ngIf="context.generateBilling">
				<label class="mb-2" for="tipoParcelamentoFromAsaas"
					>Parcelamento</label
				>
				<ng-select
					#selectPaymethodMethodsInstallmentsOptionsAsaas
					[searchable]="false"
					[clearable]="false"
					[items]="paymethodMethodsInstallmentsOptionsAsaas"
					bindLabel="description"
					bindValue="installmentCount"
					labelForId="tipoParcelamentoFromAsaas"
					formControlName="installmentCount"
					[searchFn]="customSearchFn"
				>
					>
					<ng-template ng-header-tmp>
						<input
							class="form-control"
							type="text"
							placeholder="Digite aqui para pesquisar..."
							(input)="
								customNgSelectInputSearch(
									selectPaymethodMethodsInstallmentsOptionsAsaas,
									$event
								)
							"
						/>
					</ng-template>

					<ng-template ng-option-tmp let-item="item">
						{{ item.description }}
						{{ getFirstParenthesesForBillingInCash(item)
						}}{{ item.valueFromAsaas | currencyLocale
						}}{{ getLastParenthesesForBillingInCash(item) }}
					</ng-template>

					<ng-template ng-label-tmp let-item="item">
						{{ item.description }}
						{{ getFirstParenthesesForBillingInCash(item)
						}}{{ item.valueFromAsaas | currencyLocale
						}}{{ getLastParenthesesForBillingInCash(item) }}
					</ng-template>

					<ng-template ng-notfound-tmp let-searchTerm="searchTerm">
						<div class="ng-option disabled">
							Não foram encontrados resultados para "{{
								searchTerm
							}}"
						</div>
					</ng-template>
				</ng-select>
			</div>

			<div class="col-12 col-lg-4 atlas-spacing-mt-3 mt-sm-0">
				<app-input
					labelText="Vencimento da cobrança"
					placeholderText="__ /__ /____"
					formControlName="dataVencimentoCobranca"
					[isInputDate]="true"
					[showErrors]="true"
					[isRequired]="statusDueDate"
					[minDate]="currentDate"
				></app-input>
			</div>
		</app-header-pagamento>

		<div *ngIf="!context.generateBilling" class="row mt-2 mb-4">
			<div class="col-12 col-lg-6 atlas-spacing-mt-3">
				<label class="mb-2" for="banco"
					>Banco <span class="text-muted">(Opcional)</span></label
				>
				<ng-select
					id="banco"
					class="search"
					appendTo="body"
					[items]="bankSearchresults$ | async"
					bindLabel="banco"
					bindValue="id"
					[closeOnSelect]="true"
					[hideSelected]="true"
					[typeahead]="input$"
					formControlName="banco"
					placeholder="Informe o banco"
					typeToSearchText="Digite para pesquisar"
					notFoundText="Sem resultados"
					loadingText="Carregando..."
					dropdownPosition="top"
					[loading]="loadingResults"
					(change)="selectedBank($event)"
				>
				</ng-select>
			</div>
		</div>

		<ng-container *ngIf="context.generateBilling">
			<app-header-pagamento sectionTitle="Como seu cliente poderá pagar?">
				<div class="col-7">
					<ng-container formArrayName="paymentOptions">
						<div
							*ngFor="
								let _ of paymentOptions.controls;
								index as i
							"
						>
							<div class="checkbox-element mt-3">
								<app-checkbox-details
									[id]="i"
									[options]="optionsCheckboxDetails[i]"
									[formControlName]="i"
									[showErrors]="
										!!paymentOptions.errors &&
										formStepThree.dirty
									"
									(change)="checkBillingTypeSelected()"
								></app-checkbox-details>
							</div>
						</div>
						<span
							class="error-checkbox-details"
							*ngIf="
								formStepThree.dirty &&
								paymentOptions.errors?.required
							"
							>Selecione pelo menos uma forma de pagamento para
							continuar</span
						>
					</ng-container>
				</div>
			</app-header-pagamento>
		</ng-container>

		<div class="form-group atlas-spacing-mt-4">
			<label class="mb-2" for="descricaoCobranca"
				>Descrição da cobrança
				<span class="text-muted">(Opcional)</span></label
			>
			<textarea
				class="form-control"
				id="descricaoCobranca"
				formControlName="descricaoCobranca"
				rows="3"
				placeholder="Insira uma descrição para esta cobrança"
			></textarea>
		</div>
	</form>
</ng-container>
