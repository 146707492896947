<app-wizard
	title="Criar Pedido de Venda"
	(clickCloseBtnWizard)="closeModal()"
	[showLoadingOverlay]="false"
	loadingOverlaySubTitle="Estamos resolvendo...."
>
	<ng-template appAutomataTemplate="body">
		<app-stepper #stepper linear>
			<cdk-step
				[stepControl]="formStepOne"
				label="Dados do cliente"
				#firstStep
			>
				<app-dados-do-cliente-pedido
					[formStepOne]="formStepOne"
					*appStepLazyRendering="
						stepper.selected === null ||
							stepper.selected === firstStep;
						mantainViewAfterCreation: true
					"
				></app-dados-do-cliente-pedido>
			</cdk-step>
			<cdk-step label="Dados do pedido">
				<ng-container> Step 2 </ng-container>
			</cdk-step>
			<cdk-step label="Cobrança">
				<ng-container> Step 3 </ng-container>
			</cdk-step>
			<cdk-step label="Documentos">
				<ng-container> Step 4 </ng-container>
			</cdk-step>
			<cdk-step label="Resumo">
				<ng-container> Step 5 </ng-container>
			</cdk-step>
		</app-stepper>
	</ng-template>

	<ng-template appAutomataTemplate="footer">
		<app-button (clickBtn)="closeModal()" severity="secondary">
			Fechar
		</app-button>
		<app-button (clickBtn)="goToNextStep()" severity="primary">
			Avançar
		</app-button>
	</ng-template>
</app-wizard>
