<h4 class="fw-semibold atlas-spacing-mt-4 mb-0">
	Documentos e arquivos da proposta (Opcional)
</h4>
<p class="atlas-spacing-mt-2 atlas-spacing-mb-3 text-muted">
	Os documentos adicionados são apenas para consulta e não serão
	disponibilizados para seu cliente na proposta. Você pode adicionar
	documentos ou arquivos de até {{ defaultMaxFileSizeMb }} MB.
</p>

<div
	class="files-min-height"
	[appFileDragAndDropHover]="showDragAndDropHoverEffect"
>
	<app-file-drag-and-drop
		[multiple]="true"
		[maxFileSizeMb]="defaultMaxFileSizeMb"
		accept="*"
		(handleDragEnter)="setShowDragAndDropHoverEffect($event)"
		(handleUpload)="handleUpload($event)"
	></app-file-drag-and-drop>
</div>
