import { Injectable } from "@angular/core";
import { PropostaVendaItem } from "@data/schemas/sales-proposal/sales-proposal";
import { SalesProposalItem } from "@data/schemas/sales-proposal/sales-proposal-item";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { Observable, Subject, switchMap } from "rxjs";
import { SalesProposalService } from "./sales-proposal.service";
import { DataList } from "@data/schemas/filter/data-list";

@Injectable({
	providedIn: "root"
})
export class SalesProposalItemService {
	private readonly SALES_PROPROSALS_ENDPOINT = "sales-proposals-items";

	private salesProposalItemUpdate = new Subject<PropostaVendaItem[]>();
	salesProposalItemUpdate$ = this.salesProposalItemUpdate.asObservable();

	constructor(
		private baseCrudBff: BaseCrudBffService,
		private salesProposalService: SalesProposalService
	) {}

	createSalesProposalItem(salesProposal: Partial<SalesProposalItem>) {
		return this.baseCrudBff.save({
			object: salesProposal,
			path: this.SALES_PROPROSALS_ENDPOINT
		});
	}

	updateSalesProposalItem(salesProposal: Partial<SalesProposalItem>) {
		return this.salesProposalService.actualSalesProposal$.pipe(
			switchMap((actualSalesProposal) => {
				return this.baseCrudBff.save({
					object: {
						...salesProposal,
						propostaVenda: actualSalesProposal?.id
					},
					path: this.SALES_PROPROSALS_ENDPOINT,
					patch: true
				});
			})
		);
	}

	createProductAndSalesProposalItem(
		salesProposalItemForm: SalesProposalItem & {
			codigoInterno: string;
			descricao: string;
			unidEntrada: string;
		}
	) {
		return this.salesProposalService.actualSalesProposal$.pipe(
			switchMap((actualSalesProposal) => {
				return this.baseCrudBff.save({
					object: {
						...salesProposalItemForm,
						id: undefined,
						propostaVenda: actualSalesProposal?.id
					},
					path: `${this.SALES_PROPROSALS_ENDPOINT}/product`
				});
			})
		);
	}

	deleteSalesProposalItem(idSalesProposalItem: number): Observable<unknown> {
		return this.baseCrudBff.delete({
			path: this.SALES_PROPROSALS_ENDPOINT,
			id: idSalesProposalItem
		});
	}

	setSalesProposalItemUpdate(items: PropostaVendaItem[]): void {
		this.salesProposalItemUpdate.next(items);
	}

	findPendingProposalItemsByCompany(
		companyId: string,
		size = 2,
		page = 0,
		sort = ""
	): Observable<DataList<SalesProposalItem>> {
		return this.baseCrudBff.getAll<DataList<SalesProposalItem>>({
			path: `${this.SALES_PROPROSALS_ENDPOINT}/find-proposal-pending-items-by-company/${companyId}`,
			params: { size, page, sort }
		});
	}
}
