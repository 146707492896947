<div class="row">
	<div class="col-12 col-lg-3">
		<h4 class="fw-semibold atlas-spacing-mt-4 atlas-spacing-mb-3">
			Dados da proposta
		</h4>
		<ng-container *ngIf="formStepTwoProposalValidity">
			<form [formGroup]="formStepTwoProposalValidity">
				<app-input
					formControlName="dataValidade"
					[isRequired]="false"
					[showErrors]="true"
					labelText="Validade da proposta"
					placeholderText="__ /__ /____"
					[isInputDate]="true"
					[minDate]="actualDate"
				></app-input>
			</form>
		</ng-container>
	</div>
	<div class="col-lg-5 offset-lg-4 col-12 atlas-spacing-mt-4">
		<div class="card shadow-lg border-0">
			<div class="card-body">
				<p class="fw-semibold">Opções adicionais</p>
				<span class="fw-bold h6 text-primary mb-1"
					>Gerar cobrança?</span
				>
				<div class="row">
					<div class="col-10">
						<span class="text-muted small">
							Você deseja gerar uma cobrança da proposta para seu
							cliente com o Asaas?
						</span>
					</div>
					<div class="col-2">
						<label class="switch">
							<input
								[(ngModel)]="generateBilling"
								(ngModelChange)="
									onChangeGenerateBilling($event)
								"
								type="checkbox"
							/>
							<span class="slider"></span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="formStepTwo && !showMoreItens" class="atlas-spacing-mt-4">
	<form [formGroup]="formStepTwo">
		<h4 class="fw-semibold">Qual o produto/serviço da proposta?</h4>
		<p class="text-muted">{{ selecteTextAccordingToOption() }}</p>

		<div class="row">
			<div class="col-12 col-md-6 mt-2">
				<app-select-pill
					[options]="selectPillOptions"
					formControlName="accountType"
				></app-select-pill>

				<div class="mt-3">
					<div class="form-group">
						<ng-container
							*ngIf="!selectedAccountNewProductService()"
						>
							<label class="mb-2" for="productService"
								>Produto/serviço</label
							>
							<ng-select
								id="productService"
								class="search"
								appendTo="body"
								[items]="productServiceResults$ | async"
								bindLabel="descricao"
								bindValue="descricao"
								[closeOnSelect]="true"
								[hideSelected]="true"
								[typeahead]="input$"
								formControlName="productService"
								placeholder="Informe o código interno, descrição ou ID"
								typeToSearchText="Digite para pesquisar"
								notFoundText="Sem resultados"
								loadingText="Carregando..."
								[loading]="loadingProductServiceResults"
								dropdownPosition="top"
								(change)="
									selectedProductTypeaheadOption($event)
								"
							>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
								>
									<div class="ms-4 my-2 item-body">
										<h6 class="fw-bold">
											{{ item.descricao }}
										</h6>
										<div class="my-2">
											Código Interno:
											<span class="text-muted">{{
												item.codigoInterno
											}}</span>
										</div>
										<div class="my-2">
											Saldo estoque:
											<span class="text-muted">{{
												item.saldoEstoque
											}}</span>
										</div>
										<div class="my-2">
											ID:
											<span class="text-muted">{{
												item.id
											}}</span>
										</div>
									</div>
								</ng-template>
							</ng-select>
						</ng-container>

						<ng-container
							*ngIf="selectedAccountNewProductService()"
						>
							<label class="mb-2" for="productService"
								>Produto/serviço</label
							>
							<div class="d-flex">
								<input
									id="productService"
									formControlName="productService"
									class="form-control me-2"
									placeholder="Informe um nome para o produto/serviço"
								/>

								<ng-container
									*ngTemplateOutlet="
										isProductServiceFormInvalid
											? disabledProductServiceBtn
											: enabledProductServiceBtn
									"
								>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<ng-container formArrayName="selectedProducts">
			<ng-container *ngIf="selectedProductsFormArray.controls.length > 0">
				<ng-container
					*ngFor="
						let selectedProduct of selectedProductsFormArray.controls;
						let i = index
					"
				>
					<app-header-servico
						[title]="
							selectedProduct.get('descricao')?.getRawValue()
						"
						[isExpanded]="isExpanded(i)"
						[index]="i"
						[qtde]="selectedProduct.get('qtde')?.getRawValue()"
						[precoUnitario]="
							selectedProduct.get('precoUnitario')?.getRawValue()
						"
						[precoTotal]="
							selectedProduct.get('precoTotal')?.getRawValue()
						"
						(toggleProductForm)="toggleProductForm($event)"
						(removeFormFromArray)="removeFormFromArray($event)"
					></app-header-servico>
				</ng-container>
			</ng-container>
		</ng-container>
	</form>
</div>

<div *ngIf="showMoreItens">
	<h4 class="fw-semibold atlas-spacing-mt-4 atlas-spacing-mb-2">
		Deseja incluir mais itens à sua proposta?
	</h4>
	<p class="text-muted">
		Para incluir mais um produto ou serviço, clique no botão “Adicionar
		outro produto ou serviço”.
	</p>

	<button
		[disabled]="pendingChangesSalesProposalItem"
		(click)="goBackToAddMoreItens()"
		type="button"
		class="btn btn-outline-success"
	>
		<i class="bi bi-plus-lg"></i>
		Adicionar outro produto ou serviço
	</button>

	<ng-container *ngIf="formStepTwo">
		<form [formGroup]="formStepTwo">
			<ng-container formArrayName="selectedProducts">
				<ng-container
					*ngFor="
						let selectedProduct of selectedProductsFormArray.controls;
						let i = index
					"
				>
					<app-header-servico
						[title]="
							selectedProduct.get('descricao')?.getRawValue()
						"
						[isExpanded]="isExpanded(i)"
						[index]="i"
						[qtde]="selectedProduct.get('qtde')?.getRawValue()"
						[precoUnitario]="
							selectedProduct.get('precoUnitario')?.getRawValue()
						"
						[precoTotal]="
							selectedProduct.get('precoTotal')?.getRawValue()
						"
						[itemAlreadySubmitted]="
							selectedProduct
								.get('alreadySubmitted')
								?.getRawValue()
						"
						(toggleProductForm)="toggleProductForm($event)"
						(removeFormFromArray)="removeFormFromArray($event)"
						(editFormFromArray)="showEditModeProposalItem($event)"
					></app-header-servico>

					<ng-container [formGroupName]="i">
						<div
							id="collapseBasic"
							[collapse]="!isExpanded(i)"
							[isAnimated]="true"
						>
							<div
								*ngIf="
									!selectedProduct
										.get('unlistedProduct')
										?.getRawValue()
								"
							>
								<div class="row">
									<div class="col-12 col-md-4">
										<label class="mb-2"
											>Produto/Serviço</label
										>
										<p class="fw-bold">
											{{
												selectedProduct
													.get("descricao")
													?.getRawValue()
											}}
										</p>
									</div>
									<div class="col-12 col-md-4">
										<label class="mb-2"
											>Código interno</label
										>
										<p class="fw-bold">
											{{
												selectedProduct
													.get("codigoInterno")
													?.getRawValue()
											}}
										</p>
									</div>
									<div class="col-12 col-md-4">
										<label class="mb-2"
											>Unidade de medida</label
										>
										<p class="fw-bold">
											{{
												selectedProduct
													.get("unidEntrada")
													?.getRawValue()
													| placeholder
											}}
										</p>
									</div>
								</div>

								<div class="row">
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Tipo</label
										>
										<ng-select
											appearance="outline"
											[searchable]="false"
											[clearable]="false"
											labelForId="produtoService"
											formControlName="produtoServico"
										>
											<ng-option value="servico">
												Serviço
											</ng-option>
											<ng-option value="produto">
												Produto
											</ng-option>
										</ng-select>
									</div>
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Local do estoque</label
										>
										<ng-select
											[searchable]="false"
											[clearable]="false"
											[items]="
												stockLocationsListedProduct
											"
											bindLabel="descricao"
											bindValue="id"
											formControlName="estoqueLocal"
										>
											<ng-template
												ng-option-tmp
												let-item="item"
											>
												<div class="m-2 stock-location">
													<span class="title">
														{{ item.descricao }}
													</span>
													<div>
														<span class="small">
															Saldo:
															<span>
																{{
																	item.saldoEstoque
																}}
															</span>
														</span>
													</div>
												</div>
											</ng-template>
										</ng-select>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="decimal"
											label="Saldo por estoque"
											placeholder="Informe o valor do estoque"
											formControlName="saldoPorEstoque"
											[isRequired]="true"
										></app-input-number>
									</div>
								</div>

								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Quantidade do item da
											proposta</label
										>
										<input
											id="produtoService"
											class="form-control"
											formControlName="qtde"
											mask="separator.6"
											[outputTransformFn]="replaceDot"
										/>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="currency"
											label="Preço Unitário"
											placeholder="0,00"
											formControlName="precoUnitario"
											[isRequired]="true"
											inputGroupIconText="R$"
											inputGroupIconPosition="left"
										></app-input-number>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="currency"
											label="Desconto"
											placeholder="0,00"
											formControlName="limiteDesconto"
											inputGroupIconText="%"
											inputGroupIconPosition="left"
										></app-input-number>
									</div>
								</div>

								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<app-input
											labelText="Previsão de entrega"
											placeholderText="-----"
											formControlName="prazoEntregaData"
											[showErrors]="true"
											placeholderText="__ /__ /____"
											[isInputDate]="true"
										></app-input>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											label="Valor Frete"
											mode="currency"
											formControlName="valorFrete"
											placeholder="0,00"
											inputGroupIconText="R$"
											inputGroupIconPosition="left"
											[isRequired]="false"
										></app-input-number>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12">
										<div class="form-group">
											<label
												class="mb-2"
												for="descricaoComplementar"
												>Descrição complementar
												<span class="text-muted"
													>(Opcional)</span
												></label
											>
											<textarea
												class="form-control"
												id="descricaoComplementar"
												formControlName="descricaoComplementar"
												rows="3"
												placeholder="Observações adicionais"
											></textarea>
										</div>
									</div>
								</div>
							</div>
							<div
								*ngIf="
									selectedProduct
										.get('unlistedProduct')
										?.getRawValue()
								"
							>
								<div class="row">
									<div class="col-6 text-right">
										<app-input
											labelText="Produto/serviço"
											placeholderText="Informe o produto/serviço"
											formControlName="descricao"
											[isRequired]="true"
											[showErrors]="true"
										></app-input>
									</div>
								</div>

								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<app-input
											labelText="Código interno"
											placeholderText="Informe o código interno"
											formControlName="codigoInterno"
											[isRequired]="true"
											[showErrors]="true"
										></app-input>
									</div>
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Unidade de medida
										</label>
										<ng-select
											[searchable]="false"
											[clearable]="false"
											[items]="measurementUnits"
											bindLabel="descricao"
											bindValue="id"
											formControlName="unidEntrada"
										>
										</ng-select>
									</div>
								</div>

								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Tipo</label
										>
										<ng-select
											appearance="outline"
											[searchable]="false"
											[clearable]="false"
											labelForId="produtoService"
											formControlName="produtoServico"
										>
											<ng-option value="servico">
												Serviço
											</ng-option>
											<ng-option value="produto">
												Produto
											</ng-option>
										</ng-select>
									</div>
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Local do estoque</label
										>
										<ng-select
											[searchable]="false"
											[clearable]="false"
											[items]="stockLocations"
											bindLabel="descricao"
											bindValue="id"
											formControlName="estoqueLocal"
										>
										</ng-select>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="decimal"
											label="Saldo por estoque"
											placeholder="Informe o valor do estoque"
											formControlName="saldoPorEstoque"
											[isRequired]="true"
										></app-input-number>
									</div>
								</div>

								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<label class="mb-2" for="produtoService"
											>Quantidade do item da
											proposta</label
										>
										<input
											id="produtoService"
											class="form-control"
											formControlName="qtde"
											mask="separator.6"
											[outputTransformFn]="replaceDot"
										/>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="currency"
											label="Preço Unitário"
											placeholder="0,00"
											formControlName="precoUnitario"
											[isRequired]="true"
											inputGroupIconText="R$"
											inputGroupIconPosition="left"
										></app-input-number>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="currency"
											label="Desconto"
											placeholder="0,00"
											formControlName="limiteDesconto"
											inputGroupIconText="%"
											inputGroupIconPosition="left"
										></app-input-number>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 col-md-4">
										<app-input
											labelText="Previsão de entrega"
											placeholderText="-----"
											formControlName="prazoEntregaData"
											[showErrors]="true"
											placeholderText="__ /__ /____"
											[isInputDate]="true"
										></app-input>
									</div>
									<div class="col-12 col-md-4">
										<app-input-number
											mode="currency"
											label="Valor Frete"
											formControlName="valorFrete"
											placeholder="0,00"
											inputGroupIconText="R$"
											inputGroupIconPosition="left"
											[isRequired]="false"
										></app-input-number>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12">
										<div class="form-group">
											<label
												class="mb-2"
												for="descricaoComplementar"
												>Descrição complementar
												<span class="text-muted"
													>(Opcional)</span
												></label
											>
											<textarea
												class="form-control"
												id="descricaoComplementar"
												formControlName="descricaoComplementar"
												rows="3"
												placeholder="Observações adicionais"
											></textarea>
										</div>
									</div>
								</div>
							</div>
							<div
								class="my-4 text-end"
								*ngIf="isFormOnEditModeProposalItem(i)"
							>
								<app-button
									class="me-2"
									(clickBtn)="cancelEdit(i)"
									[disabled]="addProductServiceBtnLoading"
									severity="secondary"
								>
									Cancelar Edição
								</app-button>
								<app-button
									[isLoading]="addProductServiceBtnLoading"
									(clickBtn)="updateSalesProposalItem(i)"
								>
									Salvar item
								</app-button>
							</div>

							<div
								class="my-4 text-end"
								*ngIf="
									!isFormOnEditModeProposalItem(i) &&
									!selectedProduct
										.get('alreadySubmitted')
										?.getRawValue()
								"
							>
								<app-button
									class="me-2"
									(clickBtn)="removeFormFromArray(i)"
									[disabled]="addProductServiceBtnLoading"
									severity="secondary"
								>
									Cancelar Inclusão
								</app-button>
								<app-button
									[isLoading]="addProductServiceBtnLoading"
									(clickBtn)="
										chooseHowToCreateSalesProposalItem(i)
									"
								>
									Salvar item
								</app-button>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</form>
	</ng-container>
</div>

<ng-template #disabledProductServiceBtn>
	<span
		[popover]="popoverTemplate"
		placement="top"
		triggers="mouseenter:mouseleave"
	>
		<app-button (clickBtn)="addUnlistedProduct()" [disabled]="true">
			Confirmar
		</app-button>
	</span>
</ng-template>

<ng-template #enabledProductServiceBtn>
	<app-button (clickBtn)="addUnlistedProduct()" [disabled]="false">
		Confirmar
	</app-button>
</ng-template>

<ng-template #popoverTemplate>
	<div class="py-2 px-1">
		Para confirmar, primeiro preencha o nome do novo produto
	</div>
</ng-template>
