import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormStepOneCreateSalesOrder } from "../types/form-sales-order.type";

const buildFirstStepFormGroupSalesOrder =
	(): FormGroup<FormStepOneCreateSalesOrder> =>
		new FormGroup<FormStepOneCreateSalesOrder>({
			id: new FormControl(0, {
				nonNullable: true,
				validators: []
			}),
			accountType: new FormControl(0, {
				nonNullable: true,
				validators: [Validators.required]
			}),
			razaoSocial: new FormControl("", {
				nonNullable: true,
				validators: [Validators.required]
			}),
			cnpj: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			email: new FormControl("", {
				nonNullable: true,
				validators: [Validators.required, Validators.email]
			}),
			telefone1: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			bairro: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			cep: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			cidade: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			complemento: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			endereco: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			nomeFantasia: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			numero: new FormControl("", {
				nonNullable: true,
				validators: []
			}),
			telefone2: new FormControl("", {
				nonNullable: true,
				validators: []
			})
		});

export { buildFirstStepFormGroupSalesOrder };
