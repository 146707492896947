import {
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MeasurementUnit } from "@data/schemas/measurement-unit/measurement-unit";
import { Product } from "@data/schemas/product";
import { SalesProposalItem } from "@data/schemas/sales-proposal/sales-proposal-item";
import { StockLocation } from "@data/schemas/stock-location";
import { MeasurementUnitService } from "@data/services/measurement-unit.service";
import { PaymentService } from "@data/services/payment.service";
import { ProductService } from "@data/services/product.service";
import { SalesProposalItemService } from "@data/services/sales-proposal-item.service";
import { SalesProposalService } from "@data/services/sales-proposal.service";
import { StockLocationService } from "@data/services/stock-location.service";
import { SelectPillOptions } from "@shared/components/atlas/select-pill/types";
import {
	CustomSegmentService,
	TrackInfo
} from "@core/services/custom-segment.service";
import {
	catchError,
	combineLatest,
	concat,
	debounceTime,
	distinctUntilChanged,
	filter,
	Observable,
	of,
	Subject,
	switchMap,
	takeUntil,
	tap
} from "rxjs";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { PriceTableItemService } from "@data/services/price-table-item.service";
import { DecimalTransformer } from "@shared/utils/forms/decimal-transformer";

@Component({
	selector: "app-produto-servico",
	templateUrl: "./produto-servico.component.html",
	styleUrls: ["./produto-servico.component.scss"]
})
export class ProdutoServicoComponent implements OnInit, OnDestroy {
	@Output() addNewProductStepTwo: EventEmitter<{
		product: Product;
		defaultStockLocation: number;
	}> = new EventEmitter<{
		product: Product;
		defaultStockLocation: number;
	}>();
	@Output() addNewUnlistedProductStepTwo: EventEmitter<{
		product: Partial<Product>;
		defaultStockLocation: number;
		defaultMeasurementUnit: number;
	}> = new EventEmitter<{
		product: Partial<Product>;
		defaultStockLocation: number;
		defaultMeasurementUnit: number;
	}>();
	@Output() removeProductStepTwo: EventEmitter<number> =
		new EventEmitter<number>();
	@Input() formStepTwo: FormGroup | null = null;
	@Input() formStepTwoProposalValidity: FormGroup | null = null;

	protected trackInfo!: TrackInfo;
	private readonly SEGMENT_WIZARD_TABLE =
		"base_create_wizard_sales_proposal_fullscreen";

	private readonly SEGMENT_WIZARD_SCREEN = "Step 2";

	constructor(
		private alertsService: AlertsService,
		private salesProposalService: SalesProposalService,
		private salesProposalItemService: SalesProposalItemService,
		private stockLocationService: StockLocationService,
		private measurementUnitService: MeasurementUnitService,
		private paymentService: PaymentService,
		private customSegmentService: CustomSegmentService,
		private priceTableItemService: PriceTableItemService
	) {}

	private productService = inject(ProductService);

	private productListToggleStatus: { isOpen: boolean }[] = [];

	private _onDestroy = new Subject<boolean>();

	protected editModeProposalItem: number | null = null;

	protected pendingChangesSalesProposalItem = true;

	protected selectPillOptions: SelectPillOptions[] = [
		{ label: "Produto/serviço cadastrado", value: 0 },
		{ label: "Novo produto/serviço", value: 1 }
	];

	isCollapsed = false;

	@ViewChild("input")
	protected inputRef!: ElementRef<HTMLInputElement>;

	protected showMoreItens = false;

	protected generateBilling = false;

	protected stockLocations: StockLocation[] = [];
	protected stockLocationsListedProduct: StockLocation[] = [];

	protected measurementUnits: MeasurementUnit[] = [];

	protected actualSalesProposal$ =
		this.salesProposalService.actualSalesProposal$;

	protected addProductServiceBtnLoading = false;

	protected input$ = new Subject<string>();
	protected productServiceResults$!: Observable<Product[]>;
	protected loadingProductServiceResults = false;

	ngOnInit(): void {
		this.searchAutoComplete();
		this.initDropdowns();
		this.showAlertInfoProductService();
		this.updateSalesItensProductsAfterDiscount();

		this.trackInfo = {
			table: this.SEGMENT_WIZARD_TABLE,
			screen: this.SEGMENT_WIZARD_SCREEN,
			action: ""
		};
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private showAlertInfoProductService(): void {
		this.alertsService.addAlerts({
			key: "info-product",
			message:
				"Para avançar é necessário adicionar pelo menos um produto/serviço",
			severity: "info",
			title: "Informação"
		});
	}

	private initDropdowns(): void {
		this.stockLocationService
			.getAllStockLocationsBff()
			.subscribe(
				(stockLocations) =>
					(this.stockLocations = stockLocations.content)
			);
		this.measurementUnitService
			.getAllMeasurementUnit()
			.subscribe(
				(measurementUnits) => (this.measurementUnits = measurementUnits)
			);
	}

	private searchAutoComplete(): void {
		this.productServiceResults$ = concat(
			of([]),
			this.input$.pipe(
				filter((inputText) => !!inputText),
				distinctUntilChanged(),
				debounceTime(500),
				tap(() => (this.loadingProductServiceResults = true)),
				takeUntil(this._onDestroy),
				switchMap((inputText) => {
					return this.productService.searchProducts(inputText).pipe(
						catchError(() => of([])),
						tap(() => (this.loadingProductServiceResults = false))
					);
				})
			)
		);
	}

	protected selectedAccountNewProductService(): boolean {
		return (
			(this.formStepTwo?.get("accountType")?.getRawValue() as number) ===
			1
		);
	}

	protected selecteTextAccordingToOption(): string {
		return this.selectedAccountNewProductService()
			? "Para incluir um novo produto ou serviço, informe um nome no campo abaixo e clique em “Confirmar”"
			: "Para incluir um produto ou serviço cadastrado, informe o código interno, descrição ou ID no campo abaixo e selecione a opção desejada.";
	}

	protected selectedProductTypeaheadOption(value: unknown): void {
		if (value) {
			this.trackInfo.action = "Botão Adicionar Produto Existente";
			this.customSegmentService.track(this.trackInfo);

			const product = value as Product;
			this.loadingProductServiceResults = true;

			combineLatest([
				this.stockLocationService.getStockLocationsWithBalanceByProductBff(
					product.id
				),
				this.priceTableItemService.getProductByPriceTable(
					product.id,
					Number(this.formStepTwo?.get("companyId")?.value) ?? 0
				)
			]).subscribe({
				next: ([stockLocations, priceTable]) => {
					this.stockLocationsListedProduct = stockLocations;
					product.precoUnitario = priceTable?.precoVenda;
					this.addNewProductStepTwo.emit({
						product,
						defaultStockLocation:
							this.stockLocationsListedProduct[0].id
					});
					this.showMoreItens = true;
					this.productListToggleStatus.push({ isOpen: true });
					this.loadingProductServiceResults = false;
				},
				error: () => (this.loadingProductServiceResults = false)
			});
		}
	}

	protected addUnlistedProduct(): void {
		this.showMoreItens = true;
		this.productListToggleStatus.push({ isOpen: true });
		this.addNewUnlistedProductStepTwo.emit({
			product: {
				descricao: this.formStepTwo?.get("productService")
					?.value as string
			} as Partial<Product>,
			defaultStockLocation: this.stockLocations[0].id,
			defaultMeasurementUnit:
				this.measurementUnits.find(
					(value) => value.descricao.toLowerCase() === "unidade"
				)?.id ?? 0
		});
	}

	protected checkFormValidity(): void {
		this.selectedProductsFormArray.controls.forEach((formGroup) => {
			if (formGroup instanceof FormGroup) {
				Object.keys(formGroup.controls).forEach((field) => {
					const control = formGroup.get(field);
					control?.markAsDirty();
					control?.updateValueAndValidity({ onlySelf: true });
				});
			}
		});
	}

	protected showErrorMessageInvalidForm(): void {
		if (this.formStepTwo?.invalid) {
			this.alertsService.addAlerts({
				message:
					"Antes de adicionar mais produtos e/ou serviços à sua Proposta, finalize o preenchimento dos campos obrigatórios do item atual.",
				severity: "danger"
			});
			return;
		}
	}

	protected createSalesProposalItemListedProduct(index: number): void {
		this.checkFormValidity();
		this.showErrorMessageInvalidForm();
		if (this.selectedProductsFormArray.controls[index].invalid) {
			return;
		}

		const selectedProduct = this.selectedProductsFormArray.controls[index]
			.value as SalesProposalItem;

		if (!selectedProduct) {
			this.showMoreItens = false;
			return;
		}
		if (this.formStepTwo) {
			this.pendingChangesSalesProposalItem = true;
			this.addProductServiceBtnLoading = true;

			this.actualSalesProposal$
				.pipe(
					switchMap((actualSalesProposal) => {
						return this.salesProposalItemService.createSalesProposalItem(
							{
								...selectedProduct,
								id: undefined,
								propostaVenda: actualSalesProposal?.id
							}
						);
					}),
					takeUntil(this._onDestroy)
				)
				.subscribe({
					next: (salesProposalItem) => {
						this.alertsService.addAlerts({
							message: "Produto/serviço adicionado com sucesso!",
							severity: "info"
						});
						this.selectedProductsFormArray.controls[
							index
						].patchValue(
							{
								alreadySubmitted: true,
								id: salesProposalItem.id,
								precoTotal: salesProposalItem.precoTotal
							},
							{ emitEvent: false }
						);
						this.pendingChangesSalesProposalItem = false;
						this.selectedProductsFormArray.controls[
							index
						].disable();
						this.toggleProductForm(index);
						this.addProductServiceBtnLoading = false;
					},
					error: () => {
						this.addProductServiceBtnLoading = false;
					}
				});
		}
	}

	private createProductAndSalesProposalItem(index: number): void {
		this.checkFormValidity();
		this.showErrorMessageInvalidForm();
		if (this.selectedProductsFormArray.controls[index].invalid) {
			return;
		}

		const salesProposalItemForm = this.selectedProductsFormArray.controls[
			index
		].value as SalesProposalItem & {
			codigoInterno: string;
			descricao: string;
			unidEntrada: string;
		};

		if (!salesProposalItemForm) {
			this.showMoreItens = false;
			return;
		}
		this.addProductServiceBtnLoading = true;
		this.salesProposalItemService
			.createProductAndSalesProposalItem(salesProposalItemForm)
			.subscribe({
				next: (salesProposalItem) => {
					this.trackInfo.action = "Botão Cadastrar Novo Produto";
					this.customSegmentService.track(this.trackInfo);

					this.alertsService.addAlerts({
						message: "Produto/serviço adicionado com sucesso!",
						severity: "info"
					});
					this.selectedProductsFormArray.controls[index].patchValue({
						alreadySubmitted: true,
						id: salesProposalItem.id,
						produtoId: salesProposalItem.produtoId,
						precoTotal: salesProposalItem.precoTotal
					});
					this.pendingChangesSalesProposalItem = false;
					this.selectedProductsFormArray.controls[index].disable({
						emitEvent: false
					});
					this.toggleProductForm(index);
					this.addProductServiceBtnLoading = false;
				},
				error: () => {
					this.addProductServiceBtnLoading = false;
				}
			});
	}

	private enableFormInput(inputNames: string[], index: number): void {
		inputNames.forEach((inputName) => {
			this.selectedProductsFormArray.controls[index]
				.get(inputName)
				?.enable({ emitEvent: false });
		});
	}

	protected chooseHowToCreateSalesProposalItem(index: number): void {
		if (this.isProductUnlisted(index)) {
			this.createProductAndSalesProposalItem(index);
			return;
		}
		this.createSalesProposalItemListedProduct(index);
	}

	protected updateSalesProposalItem(index: number): void {
		const salesProposalItemToEdit = this.selectedProductsFormArray.controls[
			index
		].value as Partial<SalesProposalItem>;
		this.addProductServiceBtnLoading = true;
		this.salesProposalItemService
			.updateSalesProposalItem(salesProposalItemToEdit)
			.pipe(takeUntil(this._onDestroy))
			.subscribe({
				next: (salesProposalItem) => {
					this.alertsService.addAlerts({
						message: "Produto/serviço editado com sucesso!",
						severity: "info"
					});
					this.selectedProductsFormArray.controls[index].patchValue({
						precoTotal: salesProposalItem.precoTotal,
						pendingChanges: false
					});
					this.pendingChangesSalesProposalItem = false;
					this.editModeProposalItem = null;
					this.selectedProductsFormArray.controls[index].disable({
						emitEvent: false
					});
					this.toggleProductForm(index);
					this.addProductServiceBtnLoading = false;
				},
				error: () => {
					this.addProductServiceBtnLoading = false;
				}
			});
	}

	protected goBackToAddMoreItens(): void {
		this.checkFormValidity();
		if (this.formStepTwo?.invalid) {
			this.alertsService.addAlerts({
				message:
					"Antes de adicionar mais produtos e/ou serviços à sua Proposta, finalize o preenchimento dos campos obrigatórios do item atual.",
				severity: "danger"
			});
			return;
		}
		this.formStepTwo?.get("productService")?.reset();
		this.closeAllOpenedSalesProposalItemForms();
		this.showMoreItens = false;

		this.trackInfo.action = "Botão Adicionar Item";
		this.customSegmentService.track(this.trackInfo);
	}

	private closeAllOpenedSalesProposalItemForms(): void {
		this.productListToggleStatus.forEach((productForm) => {
			if (productForm.isOpen) {
				productForm.isOpen = false;
			}
		});
	}

	protected toggleProductForm(index: number): void {
		this.productListToggleStatus[index].isOpen =
			!this.productListToggleStatus[index].isOpen;
	}

	protected isExpanded(index: number): boolean {
		return this.productListToggleStatus[index].isOpen;
	}

	protected isFormOnEditModeProposalItem(index: number): boolean {
		return this.editModeProposalItem === index;
	}

	protected cancelEdit(index: number): void {
		this.selectedProductsFormArray.controls[index].disable({
			emitEvent: false
		});
		this.pendingChangesSalesProposalItem = false;
		this.editModeProposalItem = null;
		this.productListToggleStatus[index].isOpen = false;
		this.selectedProductsFormArray.controls[index].patchValue({
			pendingChanges: false
		});
	}

	protected enableFormToListedProduct(index: number): void {
		const inputNamesToEnable = [
			"id",
			"produtoId",
			"propostaVenda",
			"qtde",
			"produtoServico",
			"estoqueLocal",
			"precoUnitario",
			"valorFrete",
			"limiteDesconto",
			"prazoEntregaData",
			"descricaoComplementar",
			"pendingChanges"
		];
		this.enableFormInput(inputNamesToEnable, index);
	}

	protected enableFormToUnlistedProduct(index: number): void {
		const inputNamesToEnable = [
			"id",
			"produtoId",
			"propostaVenda",
			"qtde",
			"produtoServico",
			"estoqueLocal",
			"saldoPorEstoque",
			"precoUnitario",
			"valorFrete",
			"limiteDesconto",
			"prazoEntregaData",
			"descricaoComplementar",
			"pendingChanges"
		];
		this.enableFormInput(inputNamesToEnable, index);
	}

	protected showEditModeProposalItem(index: number): void {
		this.selectedProductsFormArray.controls[index].patchValue({
			pendingChanges: true
		});
		if (this.isProductUnlisted(index)) {
			this.enableFormToUnlistedProduct(index);
			this.editModeProposalItem = index;
			this.productListToggleStatus[index].isOpen = true;
			return;
		}
		this.enableFormToListedProduct(index);
		this.editModeProposalItem = index;
		this.productListToggleStatus[index].isOpen = true;
	}

	protected removeFormFromArray(i: number): void {
		const salesProposalItemToRemove = this.selectedProductsFormArray
			.controls[i].value as Partial<SalesProposalItem>;
		const idSalesProposalItem = salesProposalItemToRemove.id as number;

		this.removeSalesProposalItemIfNecessary(idSalesProposalItem).subscribe({
			next: () => {
				this.alertsService.addAlerts({
					message: "Produto/serviço removido com sucesso!",
					severity: "info"
				});
				this.pendingChangesSalesProposalItem = false;
				this.productListToggleStatus.splice(i, 1);
				this.removeProductStepTwo.emit(i);
			}
		});
	}

	private removeSalesProposalItemIfNecessary(
		idSalesProposalItem: number
	): Observable<unknown> {
		if (!idSalesProposalItem) {
			return of({});
		}

		return this.salesProposalItemService.deleteSalesProposalItem(
			idSalesProposalItem
		);
	}

	private updateSalesItensProductsAfterDiscount(): void {
		this.salesProposalItemService.salesProposalItemUpdate$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((itens) => {
				itens?.forEach(({ id, precoTotal, limiteDesconto }) => {
					const productServiceToPatch =
						this.selectedProductsFormArray.controls.find(
							(form) => +form.get("id")?.value === id
						);
					productServiceToPatch?.patchValue({
						precoTotal,
						limiteDesconto
					});
				});
			});
	}

	protected onChangeGenerateBilling(status: boolean): void {
		this.customSegmentService.track({
			table: "base_create_wizard_sales_proposal_fullscreen",
			screen: "Step 2",
			action: "Switch de Gerar Cobrança "
		});
		this.paymentService.setGenerateBilling(status);

		if (status) {
			this.trackInfo.action = "Botão Gerar Cobrança";
			this.customSegmentService.track(this.trackInfo);
		}
	}

	protected replaceDot(value: string | number | undefined | null): number {
		return DecimalTransformer(value);
	}

	isProductUnlisted(index: number): boolean {
		return !!this.selectedProductsFormArray.controls[index]
			.get("unlistedProduct")
			?.getRawValue();
	}

	get selectedProductsFormArray(): FormArray {
		return this.formStepTwo?.get("selectedProducts") as FormArray;
	}

	get isProductServiceFormInvalid(): boolean {
		return !!this.formStepTwo?.get("productService")?.invalid;
	}

	get actualDate(): Date {
		return new Date();
	}
}
