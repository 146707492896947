import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject, throwError } from "rxjs";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { DataList } from "@data/schemas/filter/data-list";
import { PriceTableItem } from "@data/schemas/price-table-item";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "@core/services/auth/auth.service";
import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { PriceTableProduct } from "@data/schemas/price-table";

@Injectable({
	providedIn: "root"
})
export class PriceTableItemService {
	private readonly PRICE_TABLE_ITEM_ENDPOINT = "price-tables-items";
	private refreshPriceTableItemList = new Subject<PriceTableItem>();
	refreshPriceTableItemList$ = this.refreshPriceTableItemList.asObservable();

	private refreshAll = new BehaviorSubject<boolean>(false);
	refreshAll$ = this.refreshAll.asObservable();

	private loading = new BehaviorSubject<boolean>(false);
	loading$ = this.loading.asObservable();

	private emptyList = new BehaviorSubject<boolean>(false);
	emptyList$ = this.emptyList.asObservable();

	private notifyReload = new BehaviorSubject<boolean>(true);
	notifyReload$ = this.notifyReload.asObservable();

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private baseCrudBff: BaseCrudBffService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	setRefreshAll(status: boolean): void {
		this.refreshAll.next(status);
	}

	setLoading(status: boolean): void {
		this.loading.next(status);
	}

	setEmptyList(status: boolean): void {
		this.emptyList.next(status);
	}

	getAllPriceTableItemByProduct(
		idProduct: number
	): Observable<PriceTableProduct[]> {
		return this.http.get<PriceTableProduct[]>(
			`${this.config.environment.API_BFF}/${this.PRICE_TABLE_ITEM_ENDPOINT}/list-all-price-tables-items-by-product/${idProduct}`
		);
	}

	getAllPriceTableItemByPriceTable(
		idPriceTable: number,
		httpParamsConfig?: HttpParamsConfig
	): Observable<{
		dataListPriceTableItem: DataList<PriceTableItem>;
	}> {
		if (httpParamsConfig) {
			if (httpParamsConfig.fields) {
				httpParamsConfig.fields.push({
					key: "idPriceTable",
					value: idPriceTable.toString()
				});
			} else {
				httpParamsConfig.fields = [
					{ key: "idPriceTable", value: idPriceTable.toString() }
				];
			}
		}
		const params = this.getAllFiltersParams(httpParamsConfig);
		return this.baseCrudBff
			.getAll<DataList<PriceTableItem>>({
				path: `${this.PRICE_TABLE_ITEM_ENDPOINT}/list-all-price-tables-items`,
				params
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							dataListPriceTableItem: DataList<PriceTableItem>;
						}
				)
			);
	}

	private getAllFiltersParams(httpParamsConfig?: HttpParamsConfig): {
		[param: string]: string | number;
	} {
		let params = {};
		if (httpParamsConfig) {
			const { fields, ...filters } = httpParamsConfig;
			if (fields) {
				fields.forEach((item) => {
					const fieldsParams = {
						[item.key]: item.value
					};
					params = { ...params, ...fieldsParams };
				});
			}
			params = { ...params, ...filters };
		}
		return params;
	}

	remove(priceTableId: number): Observable<unknown> {
		return this.http.delete<unknown>(
			`${this.config.environment.API_BFF}/${
				this.PRICE_TABLE_ITEM_ENDPOINT
			}/${priceTableId}?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				withCredentials: true
			}
		);
	}

	savePriceTableItem(priceTableItem: PriceTableItem): Observable<{
		priceTableItem: PriceTableItem;
	}> {
		return this.baseCrudBff
			.save({
				path: `${this.PRICE_TABLE_ITEM_ENDPOINT}`,
				object: priceTableItem
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							priceTableItem: PriceTableItem;
						}
				)
			);
	}

	addAllProducts(
		idPriceTable: number,
		priceTableItem: Partial<PriceTableItem>
	): Observable<{ priceTableItemDataList: DataList<PriceTableItem> }> {
		return this.baseCrudBff
			.save({
				path: `${this.PRICE_TABLE_ITEM_ENDPOINT}/${idPriceTable}/add-all-products-price-table-items`,
				object: priceTableItem
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							priceTableItemDataList: DataList<PriceTableItem>;
						}
				)
			);
	}

	addByProduct(
		idPriceTable: number,
		priceTableItem: Partial<PriceTableItem>
	): Observable<{
		priceTableItem: PriceTableItem;
	}> {
		return this.baseCrudBff
			.save({
				path: `${this.PRICE_TABLE_ITEM_ENDPOINT}/${idPriceTable}/add-by-product-price-table-items`,
				object: priceTableItem
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							priceTableItem: PriceTableItem;
						}
				)
			);
	}

	addByProductGroup(
		idPriceTable: number,
		priceTableItem: Partial<PriceTableItem>
	): Observable<{ priceTableItemDataList: DataList<PriceTableItem> }> {
		const idProductGroup = priceTableItem.productGroup?.id;
		if (!idProductGroup) {
			return throwError(() => new Error("Grupo não pode ser nulo"));
		}
		return this.baseCrudBff
			.save({
				path: `${this.PRICE_TABLE_ITEM_ENDPOINT}/${idPriceTable}/add-by-product-group-price-table-items/${idProductGroup}`,
				object: priceTableItem
			})
			.pipe(
				map(
					(result) =>
						result as unknown as {
							priceTableItemDataList: DataList<PriceTableItem>;
						}
				)
			);
	}

	setRefreshPriceTableItemList(items: PriceTableItem): void {
		this.refreshPriceTableItemList.next(items);
	}

	actionNotifyReload(): void {
		this.notifyReload.next(true);
	}

	getProductByPriceTable(
		idProduct: number,
		idCompany: number
	): Observable<PriceTableItem> {
		return this.http.get<PriceTableItem>(
			`${this.config.environment.API_BFF}/${
				this.PRICE_TABLE_ITEM_ENDPOINT
			}/${idProduct}/get-product-selling-price/${idCompany}?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				withCredentials: true
			}
		);
	}
}
