<div class="atlas-spacing-mt-4">
	<div class="d-flex atlas-spacing-mb-3">
		<h4 class="title-section mb-0">{{ sectionTitle }}</h4>
		<span
			tabindex="0"
			*ngIf="showPopover"
			class="mb-2 ms-1 align-self-center cursor-pointer text-primary"
			[popoverTitle]="popoverTitle"
			[popover]="popoverDescription"
			[adaptivePosition]="true"
			container="body"
			boundariesElement="viewport"
			[placement]="popoverPosition"
		>
			<i class="bi bi-info-circle popover-info"></i>
		</span>
	</div>

	<div class="row">
		<ng-content></ng-content>
	</div>
</div>
