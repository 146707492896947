<app-alerts [toastMode]="true"> </app-alerts>
<app-loading-overlay
	[show]="showCancelLoading"
	subtitle="Estamos cancelando a criação da sua proposta comercial"
></app-loading-overlay>
<div class="d-flex flex-column vh-100">
	<div class="modal-header">
		<div class="w-100 text-center">
			<h5>
				<strong>Criar Proposta Comercial</strong>
			</h5>
		</div>
		<button
			type="button"
			class="btn-close close pull-right"
			aria-label="Close"
			(click)="undoSalesProposal()"
		>
			<span aria-hidden="true" class="visually-hidden">&times;</span>
		</button>
	</div>
	<div class="modal-body bg-zindex">
		<div class="my-4">
			<app-stepper #stepper linear>
				<cdk-step [stepControl]="stepOneForm" label="Dados do cliente">
					<app-dados-do-cliente
						[formStepOne]="stepOneForm"
					></app-dados-do-cliente>
				</cdk-step>

				<cdk-step [stepControl]="stepTwoForm" label="Produto/Serviço">
					<app-produto-servico
						[formStepTwo]="stepTwoForm"
						[formStepTwoProposalValidity]="
							stepTwoFormProposalValidity
						"
						(addNewProductStepTwo)="addNewProductStepTwo($event)"
						(addNewUnlistedProductStepTwo)="
							addNewUnlistedProductStepTwo($event)
						"
						(removeProductStepTwo)="removeProductStepTwo($event)"
					></app-produto-servico>
				</cdk-step>

				<cdk-step label="Pagamento">
					<app-pagamento
						[formStepThree]="stepThreeForm"
					></app-pagamento>
				</cdk-step>

				<cdk-step label="Documentos">
					<app-documentos
						(uploadFiles)="addFilesToUpload($event)"
					></app-documentos>
				</cdk-step>

				<cdk-step label="Resumo">
					<app-resumo
						[formStepOne]="stepOneForm"
						[formStepTwo]="stepTwoForm"
						[formStepTwoProposalValidity]="
							stepTwoFormProposalValidity
						"
						[formStepThree]="stepThreeForm"
						[filesToUpload]="filesToUpload"
						(goToStep)="goToStep($event)"
					></app-resumo>
				</cdk-step>
			</app-stepper>
		</div>
	</div>
	<div class="modal-footer">
		<app-button
			*ngIf="stepper.selectedIndex === 0"
			(clickBtn)="undoSalesProposal('footer')"
			[disabled]="goToNextStepBtnLoading"
			severity="secondary"
		>
			Fechar
		</app-button>

		<app-button
			*ngIf="stepper.selectedIndex > 0"
			(clickBtn)="goToPreviousStep()"
			[disabled]="goToNextStepBtnLoading"
			severity="secondary"
		>
			Voltar
		</app-button>

		<app-button
			*ngIf="stepper.selectedIndex < 4"
			(clickBtn)="goToNextStep()"
			[isLoading]="goToNextStepBtnLoading"
			[disabled]="setNextBtnDisabledStatusAccordingToTheStep()"
		>
			Avançar
		</app-button>

		<app-button
			*ngIf="stepper.selectedIndex === 4"
			severity="success"
			(clickBtn)="finishSalesProposal()"
		>
			Finalizar Proposta
		</app-button>
	</div>
</div>
