import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import { SalesProposal } from "@data/schemas/sales-proposal/sales-proposal";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class SalesProposalService {
	private readonly SALES_PROPROSALS_ENDPOINT = "sales-proposals";
	private actualSalesProposal = new BehaviorSubject<
		{ id: number } | undefined
	>(undefined);
	actualSalesProposal$ = this.actualSalesProposal.asObservable();

	constructor(
		private baseCrudBff: BaseCrudBffService,
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	getSalesProposalById(id: number): Observable<SalesProposal> {
		return this.baseCrudBff.getById<SalesProposal>(
			this.SALES_PROPROSALS_ENDPOINT,
			id
		);
	}

	createSalesProposal(
		salesProposal: Partial<SalesProposal>
	): Observable<Partial<SalesProposal>> {
		return this.baseCrudBff.save({
			path: this.SALES_PROPROSALS_ENDPOINT,
			object: salesProposal
		});
	}

	updateSalesProposal(
		salesProposal: Partial<SalesProposal>
	): Observable<Partial<SalesProposal>> {
		return this.baseCrudBff.save({
			path: this.SALES_PROPROSALS_ENDPOINT,
			object: salesProposal,
			patch: true
		});
	}

	setActualSalesProposal(salesProposal: { id: number } | undefined): void {
		this.actualSalesProposal.next(salesProposal);
	}

	applyTotalDiscount({
		id,
		discountValue,
		isPercentage
	}: {
		id: number;
		discountValue: number;
		isPercentage: boolean;
	}): Observable<
		Pick<
			SalesProposal,
			"totalPropostaVenda" | "totalProduto" | "propostaVendaItens"
		>
	> {
		return this.http.put<
			Pick<
				SalesProposal,
				"totalPropostaVenda" | "totalProduto" | "propostaVendaItens"
			>
		>(
			`${this.config.environment.API_BFF}/sales-proposals/${id}/apply-total-discount`,
			{
				discountValue,
				isPercentage,
				hashedFingerprint: this.authService.getHashedFingerprint()
			},
			{
				withCredentials: true
			}
		);
	}

	applyTotalShipping({
		id,
		shippingValue
	}: {
		id: number;
		shippingValue: number;
	}): Observable<Pick<SalesProposal, "totalPropostaVenda">> {
		return this.http.put<Pick<SalesProposal, "totalPropostaVenda">>(
			`${this.config.environment.API_BFF}/sales-proposals/${id}/apply-total-shipping`,
			{
				shippingValue,
				hashedFingerprint: this.authService.getHashedFingerprint()
			},
			{
				withCredentials: true
			}
		);
	}

	sendEmailSalesProposal({
		salesProposalId
	}: {
		salesProposalId: number;
	}): Observable<unknown> {
		return this.http.get(
			`${
				this.config.environment.API_BFF
			}/sales-proposals/${salesProposalId}/send-email-sales-proposal?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				withCredentials: true
			}
		);
	}

	downloadSalesProposalPDF({
		salesProposalId
	}: {
		salesProposalId: number;
	}): Observable<unknown> {
		let headers = new HttpHeaders();
		headers = headers.set("Accept", "application/pdf");

		return this.http.get(
			`${
				this.config.environment.API_BFF
			}/sales-proposals/${salesProposalId}/download-sales-proposal-pdf?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				headers: headers,
				responseType: "Blob" as "json",
				withCredentials: true
			}
		);
	}

	undoSalesProposal({
		salesProposalId
	}: {
		salesProposalId: number;
	}): Observable<unknown> {
		return this.http.delete(
			`${
				this.config.environment.API_BFF
			}/sales-proposals/${salesProposalId}/undo-sales-proposal?hashedFingerprint=${
				this.authService.getHashedFingerprint() ?? ""
			}`,
			{
				withCredentials: true
			}
		);
	}
}
