import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CreateSalesOrderService {
	private destroyCreateSalesOrder = new Subject<boolean>();
	destroyCreateSalesOrder$ = this.destroyCreateSalesOrder.asObservable();

	doSomeRequest(): Observable<never[]> {
		return of([]);
	}

	emitDestroySalesOrder(value: boolean): void {
		this.destroyCreateSalesOrder.next(value);
	}
}
